import { useQuery, useReactiveVar } from '@apollo/client';
import moment from 'moment';
import React, { useMemo } from 'react';
import { shoppingCartOffersVar } from '../apollo/reactive-variables/shopping-cart';
import { BulletList } from '../components/lists';
import { SelectBankAccount } from '../components/selects';
import { FINGO_INFO } from '../constants/fingo-data';
import { MY_OFFERS } from '../graphql';
import { formatDayMonthYearSlash } from '../helpers';

const useExternalOfferShoppingCart = ({ bankId, setBankId }) => {
  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);
  const invoiceIds = shoppingCartOffers.map(({ invoiceId }) => (invoiceId));
  const { data: offers, loading } = useQuery(
    MY_OFFERS,
    {
      variables: { id_In: invoiceIds, first: 100 },
      skip: !invoiceIds.length,
    },
  );

  const invoicesFormated = useMemo(() => (
    offers?.invoices.edges.map(
      ({ node }) => `Folio ${node.folio} - Fecha de pago: ${formatDayMonthYearSlash(moment(shoppingCartOffers.find(({ invoiceId }) => invoiceId === node.id).dateToPay))}`,
    )
  ), [offers]);

  const paragraphs = useMemo(() => ([
    {
      id: 1,
      title: 'Ingresa a tu facturador electrónico y cédenos las siguientes facturas:',
      content: <BulletList list={invoicesFormated} />,
    },
    {
      id: 2,
      title: 'Para ceder debes ingresar los siguientes datos:',
      content: <BulletList list={FINGO_INFO} />,
    },
    {
      id: 3,
      title: 'Selecciona la cuenta del banco a la que se girará el dinero:',
      content: <SelectBankAccount
        bankId={bankId}
        setBankId={setBankId}
        hideText
      />,
    },
    {
      id: 4,
      title: 'Una vez que las facturas estén cedidas, confirma tu cesión haciendo click en el botón "Operar con Fingo"',
      content: null,
    },
  ]), [offers, bankId]);

  return { paragraphs, loading };
};

export default useExternalOfferShoppingCart;
