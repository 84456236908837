import useGetUser from './useGetUser';

const useUserHasPerm = (permission) => {
  const user = useGetUser();
  if (user.isSuperuser) {
    return true;
  }
  return user.permissions.includes(permission);
};

export default useUserHasPerm;
