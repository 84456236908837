import { useQuery } from '@apollo/client';
import { GET_INVOICE } from '../graphql';

const useGetInvoice = (invoiceId, query = GET_INVOICE) => {
  const invoice = useQuery(query, {
    variables: {
      invoiceId,
    },
    skip: !invoiceId,
  });
  return invoice.data?.invoice;
};

export default useGetInvoice;
