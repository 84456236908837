import { useQuery } from '@apollo/client';
import { GET_MASTER_ENTITY } from '../graphql';

const useGetMasterEntity = (companyId, skip = false, queryDocument = GET_MASTER_ENTITY) => {
  const masterEntity = useQuery(queryDocument, {
    variables: {
      masterEntityId: companyId,
    },
    skip: !companyId || skip,
  });
  return masterEntity.data?.getMasterEntity;
};

export default useGetMasterEntity;
