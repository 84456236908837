import { useCallback, useState } from 'react';

// Always use with use callback in the react component
const useDebounce = (func, delay) => {
  const [timer, setTimer] = useState();
  const callback = useCallback((args) => {
    clearTimeout(timer);
    const timeout = setTimeout(() => func(args), delay);
    setTimer(timeout);
  }, [timer]);
  return callback;
};

export default useDebounce;
