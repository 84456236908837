import moment from 'moment';
import { useCallback, useState } from 'react';

const useLastRefreshDate = () => {
  const [lastUpdated, setLastUpdated] = useState(moment().format('DD/MM/YYYY - HH:mm:ss'));
  const refreshDateHandler = useCallback(() => {
    const timestamp = moment().format('DD/MM/YYYY - HH:mm:ss');
    setLastUpdated(timestamp);
  }, []);
  return [lastUpdated, refreshDateHandler];
};

export default useLastRefreshDate;
