import { useMutation } from '@apollo/client';
import useSnackBars from './useSnackBars';
import { formatSelectedMailParam } from '../propTypes';

import { CREATE_UPDATE_MAIL_TEMPLATE } from '../graphql';

const useCreateUpdateMailTemplate = ({
  editMode,
  companyId,
  addControlGroupBlackList,
  addControlGroup,
  addControlGroupsByFile,
  setMailParamId,
  setSelectedMailParam,
  setSelectedMail,
}) => {
  const { addAlert } = useSnackBars();

  const addNewParamToMail = (mailParam) => {
    setSelectedMail((prevState) => {
      const newMail = { ...prevState };
      const newMailParams = newMail.mailParams
        .filter(
          (param) => !(
            param.country.id === mailParam.country.id
            && param.requestingPlatform.id === mailParam.requestingPlatform.id
            && param.template.version === null
          ),
        )
        .concat(mailParam);
      return { ...newMail, mailParams: newMailParams };
    });
    setSelectedMailParam(mailParam);
  };

  const [createUpdateMailTemplate, { loading }] = useMutation(
    CREATE_UPDATE_MAIL_TEMPLATE,
    {
      onCompleted: (data) => {
        let message = 'Plantilla creada';
        if (editMode) { message = 'Plantilla actualizada'; }
        if (addControlGroupBlackList) { message = 'Empresa añadida a la Blacklist para este mail'; }
        if (!addControlGroupsByFile) {
          addAlert({
            id: 'new-mail-template',
            message,
          });
        }
        // if created a blacklist, assign it
        const mailParam = data.createUpdateMailTemplate.mailParameter;
        const formatedMailParam = formatSelectedMailParam(mailParam);
        if (addControlGroupBlackList) {
          if (addControlGroup) {
            addControlGroup({ variables: { companyId, mailParameterId: mailParam.id } });
          } else if (addControlGroupsByFile) {
            setMailParamId(mailParam.id);
            addControlGroupsByFile();
          }
        } else {
          addNewParamToMail(formatedMailParam);
          setSelectedMailParam(formatedMailParam);
        }
      },
      onError: (error) => {
        let { message } = error;
        if (error.message.includes('mail_template_version')) {
          message = 'La versión tiene que ser una letra del abecedario en mayúscula';
        } else if (error.message.includes('unique constraint')) {
          message = 'La combinación de la plantilla { versión - sendrid id } ya existe';
        }
        addAlert({
          id: 'new-mail-error',
          message,
          severity: 'error',
        });
      },
    },
  );

  return { createUpdateMailTemplate, loading };
};

export default useCreateUpdateMailTemplate;
