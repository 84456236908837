import { useQuery } from '@apollo/client';
import { GET_USER_MAILS } from '../graphql/user';

const useGetUserMails = (filters) => {
  const { data, loading, ...extraQuery } = useQuery(
    GET_USER_MAILS,
    { variables:
      {
        orderBy: 'email',
        ...filters,
      },
    },
  );
  return {
    loading,
    users: data?.getUsers?.edges?.map((i) => i.node) || [],
    data,
    ...extraQuery,
  };
};

export default useGetUserMails;
