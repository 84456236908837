import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { checkRut, prettifyRut, removeSeparators } from 'react-rut-formatter';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { GET_NATIONAL_INVOICE_PROVIDER, UPLOAD_CREDENTIALS } from '../graphql';
import { FingoBoldEmail } from '../components/links';
import { validateRfc } from '../helpers';
import useSelectedCompany from './useSelectedCompany';
import useGetCountryFromUrl from './useGetCountryFromUrl';
import { toggleSiiForm } from '../apollo/reactive-variables/alerts';

const FORMATTERS = {
  Chile: (rut) => (rut !== '0' || '' ? prettifyRut(rut) : ''),
  Mexico: (rfc) => rfc?.toUpperCase().replace(/ /g, '') || '',
};

const VALIDATORS = {
  Chile: (rut) => checkRut(rut),
  Mexico: (rfc) => validateRfc(rfc),
};

const SETTERS = {
  Chile: (rut) => removeSeparators(rut),
  Mexico: (rfc) => rfc,
};

const useNationalTaxServiceCredentialsInput = (
  source,
  onCompleted,
  onError,
  registerOrigin = null,
) => {
  const [nationalIdentifier, setNationalIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const { autoFillCompany } = useReactiveVar(toggleSiiForm);
  const selectedCompany = useSelectedCompany();
  const countryFromUrl = useGetCountryFromUrl();
  const { t } = useTranslation();

  const country = useMemo(
    () => selectedCompany?.masterEntity?.country || countryFromUrl,
    [selectedCompany, countryFromUrl],
  );
  const { data } = useQuery(GET_NATIONAL_INVOICE_PROVIDER, {
    variables: { countryId: country?.id },
  });

  const updateNationalIdentifier = useCallback((newNationalIdentifier) => {
    setNationalIdentifier(SETTERS[country.name](newNationalIdentifier));
  }, []);

  const formattedNationalIdentifier = useMemo(
    () => FORMATTERS[country.name](nationalIdentifier),
    [country, nationalIdentifier],
  );

  const onChangeInput = useCallback((event) => {
    const handlers = {
      nationalIdentifier: updateNationalIdentifier,
      password: setPassword,
    };
    const { name, value } = event.target;
    handlers[name](value);
  }, []);

  const clearFields = useCallback(() => {
    setPassword(null);
  }, []);

  const [uploadCredentials, { loading }] = useMutation(UPLOAD_CREDENTIALS, {
    variables: {
      username: formattedNationalIdentifier,
      password,
      credentialProvider: data?.getNationalInvoiceProvider?.id,
      source,
      companyId: autoFillCompany ? selectedCompany?.id : null,
      origin: registerOrigin,
    },
    onError: (error) => {
      if (error.message === 'invalid_credentials') {
        setErrorMessage(
          <Typography variant="body1" color="black">
            El {t('National identifier')} o la contraseña son incorrectos.
            Por favor, verifica tus datos e inténtalo de nuevo.
          </Typography>,
        );
      } else if (error.message === 'Account temporarily blocked due to several incorrect attempts to login') {
        setErrorMessage(
          <Typography variant="body1" color="black">
            Varios intentos incorrectos de inicio de sesión. Intenta denuevo más tarde.
          </Typography>,
        );
      } else {
        setErrorMessage(
          <Typography variant="body1" color="black">
            Hubo un error al verificar tu empresa. Contáctate con nosotros en{' '}
            <FingoBoldEmail />
          </Typography>,
        );
      }
      onError();
    },
    onCompleted: () => {
      clearFields();
      if (source === 'Lokal') {
        onCompleted(formattedNationalIdentifier);
      } else {
        onCompleted();
      }
    },
  });

  const submit = useCallback((variables) => {
    uploadCredentials(variables);
  }, []);

  const isValid = useMemo(
    () => VALIDATORS[country.name](nationalIdentifier) && password?.length > 0,
    [nationalIdentifier, password],
  );

  useEffect(() => {
    if (autoFillCompany) {
      updateNationalIdentifier(
        selectedCompany.masterEntity.displayNationalIdentifier,
      );
    }
  }, [autoFillCompany, selectedCompany]);

  return {
    nationalIdentifier: formattedNationalIdentifier,
    password,
    onChangeInput,
    setNationalIdentifier,
    loading,
    errorMessage,
    submit,
    isValid,
    credentialProvider: data,
  };
};

export default useNationalTaxServiceCredentialsInput;
