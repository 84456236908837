import { useQuery } from '@apollo/client';
import { GET_BANK_ACCOUNT_TYPES } from '../graphql';

const useBankAccountTypes = (countryId) => {
  const { data: accountTypes, loading: loadingAccountTypes } = useQuery(GET_BANK_ACCOUNT_TYPES, {
    variables: {
      countryId,
    } });
  return { accountTypes, loadingAccountTypes };
};

export default useBankAccountTypes;
