import { useState, useCallback, useEffect } from 'react';

const useAvailableHeight = (summingOffset = 0) => {
  const [element, setElement] = useState();
  const [height, setHeight] = useState(10);
  const calculateAvailableHeight = useCallback((node) => {
    const offset = node.parentElement === node.offsetParent
      ? node.offsetTop : node.offsetTop - node.parentElement.offsetTop;
    return `calc(100% - ${offset}px - ${summingOffset}px)`;
  }, []);
  const setDefaultHeight = useCallback(() => {
    if (!element) return;
    setHeight(calculateAvailableHeight(element));
  }, [element, calculateAvailableHeight]);
  const refChange = useCallback((node) => {
    if (node === null) {
      return;
    }
    setElement(node);
    setHeight(calculateAvailableHeight(node));
  });
  useEffect(() => {
    window.addEventListener('resize', setDefaultHeight);
    return () => window.removeEventListener('resize', setDefaultHeight);
  }, []);
  return [height, refChange];
};

export default useAvailableHeight;
