import { useCallback, useState } from 'react';
import useDebounce from './useDebounce';

const useDebounceState = (initialState, callback) => {
  const debounce = useDebounce(callback, 500);
  const [finderValue, _setFinderValue] = useState(initialState);
  const setFinderValue = useCallback((newValue) => {
    _setFinderValue(newValue);
    debounce(newValue);
  }, [debounce, _setFinderValue]);
  const handleOnChangeEvent = useCallback((event) => {
    const { target: { value: newValue } } = event;
    setFinderValue(newValue);
  }, [_setFinderValue, debounce]);
  return [finderValue, setFinderValue, handleOnChangeEvent];
};

export default useDebounceState;
