import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SelectRadioGroup } from '../components/selects';
import { ContactList } from '../components/lists';
import FileChipsList from '../components/lists/FileChipsList';
import {
  GET_COLLECTION_MAIL_ENUM,
  GET_COLLECTION_MAIL_PREVIEW,
  SEND_COLLECTION_MAIL,
} from '../graphql';
import useSnackBars from './useSnackBars';
import useEnum from './useEnum';
import FileChip from '../components/chips/FileChip';
import { getLatestItemByProperty } from '../helpers/arrays';
import MailPreviewDialog from '../components/dialogs/MailPreviewDialog';
import { CONTACT_TYPES, FINGO_MASTER_ENTITY_ID } from '../constants';

const useSendCollectionMailSteps = (
  debtor,
  documents,
  showAssignments,
  showAllContactTypes,
  columns,
) => {
  const [mailType, setMailType] = useState();
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [selectedAssignmentIds, setSelectedAssignmentIds] = useState([]);

  const hasDocuments = documents.flatMap((document) => document.documents).length > 0;
  const [options] = useEnum(GET_COLLECTION_MAIL_ENUM);
  const { addAlert } = useSnackBars();

  const resetFields = useCallback(() => {
    setMailType(null);
    setSelectedFileIds([]);
    setSelectedContactIds([]);
  }, []);

  const collectionManagerIds = documents.map(
    (document) => document.collectionManager.id,
  );
  const [sendCollectionMail, { loading }] = useMutation(SEND_COLLECTION_MAIL, {
    variables: {
      collectionManagerIds,
      attachmentIds: selectedFileIds,
      assignmentIds: selectedAssignmentIds,
      collectionContactIds: selectedContactIds,
      mailType,
    },
    onCompleted: () => {
      addAlert({
        id: 0,
        color: 'primary',
        severity: 'success',
        message: 'Mail enviado con éxito',
      });
      resetFields();
    },
  });

  const steps = [
    {
      title: 'Selecciona tipo de mail',
      component: (
        <Stack direction="row" spacing={1}>
          <SelectRadioGroup
            options={options}
            setOption={setMailType}
            selectedOption={mailType}
          />
          {hasDocuments && <Divider orientation="vertical" flexItem />}
          {hasDocuments && (
            <Stack spacing={1}>
              <Typography marginX="Auto" marginBottom={1} variant="h2">
                Adjuntar archivos
              </Typography>
              {documents.map((document) => {
                const files = document?.documents?.map((file) => ({
                  id: file.id,
                  url: '/',
                  label: file.type.name,
                }));
                const lastAssignment = (showAssignments && document?.assignmentSet)
                  ? getLatestItemByProperty(document.assignmentSet.filter(
                    // eslint-disable-next-line eqeqeq
                    ({ toMasterEntity }) => toMasterEntity.rut == FINGO_MASTER_ENTITY_ID,
                  ), 'createdAt')
                  : null;
                return (
                  (!!files?.length || lastAssignment) && (
                    <Stack key={document.id} alignItems="center">
                      <Typography variant="body2">
                        Folio {document.folio}
                      </Typography>
                      <FileChipsList
                        files={files}
                        selectedFileIds={selectedFileIds}
                        setSelectedFileIds={setSelectedFileIds}
                        size="small"
                      />
                      {lastAssignment && (
                        <FileChip
                          file={{
                            id: lastAssignment.id,
                            label: 'Certificado de cesión',
                            url: lastAssignment.certificate,
                          }}
                          selectedFileIds={selectedAssignmentIds}
                          setSelectedFileIds={setSelectedAssignmentIds}
                          size="small"
                        />
                      )}
                    </Stack>
                  )
                );
              })}
            </Stack>
          )}
        </Stack>
      ),
      backButton: {
        label: 'Atrás',
      },
      nextButton: {
        disabled: !mailType,
        label: 'Siguiente',
      },
    },
    {
      title: 'Selecciona contactos',
      component: (
        <ContactList
          showAllContactTypes={showAllContactTypes}
          masterEntity={debtor}
          contactType={CONTACT_TYPES.COLLECTION}
          setSelectedContactIds={setSelectedContactIds}
          selectedContactIds={selectedContactIds}
          columns={columns}
        />
      ),
      backButton: {
        label: 'Atrás',
      },
      nextButton: {
        disabled: selectedContactIds.length === 0,
        label: 'Ir a vista previa',
      },
      maxWidth: '70%',
      fullWidth: true,
    },
    {
      title: 'Vista previa',
      component: (
        <MailPreviewDialog
          query={GET_COLLECTION_MAIL_PREVIEW}
          variables={{ collectionManagerIds, mailType }}
        />
      ),
      backButton: {
        label: 'Atrás',
      },
      nextButton: {
        label: 'Enviar correo',
        loading,
        handler: sendCollectionMail,
      },
    },
  ];

  return [steps, resetFields];
};

export default useSendCollectionMailSteps;
