import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { orderingSimulationSelection } from '../../apollo/cache/localQuery';
import { formatYearMonthDay } from '../../helpers';
import { ORDERING_SHOPPING_CART } from '../../graphql';

const useOrderingShoppingCart = () => {
  const { data: {
    selectedOrderingIds,
    orderingInvoiceIssuedSelected,
  } } = useQuery(orderingSimulationSelection);
  const formatInvoiceIssued = useCallback((ocId) => {
    if (orderingInvoiceIssuedSelected[ocId]) {
      return formatYearMonthDay(orderingInvoiceIssuedSelected[ocId]);
    }
    return null;
  }, [orderingInvoiceIssuedSelected]);
  const { data: shoppingCartData, loading } = useQuery(
    ORDERING_SHOPPING_CART,
    {
      variables: {
        purchaseOrders: selectedOrderingIds.map((ocId) => ({
          purchaseOrderId: ocId,
          invoiceIssuedDate: formatInvoiceIssued(ocId),
        })),
      },
    },
  );
  const notSelectedDate = useCallback(
    () => selectedOrderingIds.filter(
      (nodeId) => !orderingInvoiceIssuedSelected[nodeId],
    ),
    [selectedOrderingIds, orderingInvoiceIssuedSelected],
  );
  const disabledShowCartFunction = useCallback(
    () => notSelectedDate().length > 0,
    [notSelectedDate],
  );
  const disabledShowCart = disabledShowCartFunction();
  const thisShoppingCartData = shoppingCartData?.orderingShoppingCart;
  return { shoppingCartData: thisShoppingCartData, disabledShowCart, loading };
};

export default useOrderingShoppingCart;
