import { useQuery } from '@apollo/client';
import { GET_GENERIC_EXECUTIVES } from '../graphql';

const useGenericGetExecutives = (filters) => {
  const { data, loading, ...extraQuery } = useQuery(
    GET_GENERIC_EXECUTIVES,
    { variables:
      {
        orderBy: 'firstName',
        ...filters,
      },
    },
  );
  return {
    loading,
    executives: data?.getUsers?.edges || [],
    data,
    ...extraQuery,
  };
};

export default useGenericGetExecutives;
