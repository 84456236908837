import { useQuery } from '@apollo/client';
import { PURCHASE_ORDER } from '../graphql/ordering/queries';

const useGetPurchaseOrder = (purchaseOrderId, query = PURCHASE_ORDER) => {
  const { data, loading, ...extraQuery } = useQuery(query, {
    variables: {
      purchaseOrderId,
    },
    skip: !purchaseOrderId,
  });
  return {
    loading,
    purchaseOrder: data?.purchaseOrder,
    ...extraQuery,
  };
};

export default useGetPurchaseOrder;
