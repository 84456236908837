import useGetUser from './useGetUser';
import useGetCountryFromUrl from './useGetCountryFromUrl';

const useSelectedCompany = () => {
  const user = useGetUser();
  const country = useGetCountryFromUrl();
  if (user?.selectedCompany && user?.selectedCompany?.masterEntity.country?.id === country?.id) {
    return user?.selectedCompany;
  }
  return null;
};

export default useSelectedCompany;
