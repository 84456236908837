import useGetCountryFromUrl from './useGetCountryFromUrl';

export const useGetCurrenciesFromCompany = ({ masterEntity }) => {
  const sortedCurrencies = masterEntity?.currencies;
  return sortedCurrencies || [];
};

export const useGetCurrenciesFromCountry = () => {
  const country = useGetCountryFromUrl();
  return country?.currencies || [];
};
