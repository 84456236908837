import { useOfferShoppingCart, useOrderingShoppingCart, usePreofferShoppingCart } from './useShoppingCartHooks';
import { OFFERS, PREOFFERS, ORDERING } from '../constants';

const useShoppingCart = ({ cartType }) => {
  if (cartType === OFFERS) {
    return useOfferShoppingCart();
  }
  if (cartType === ORDERING) {
    return useOrderingShoppingCart();
  }
  if (cartType === PREOFFERS) {
    return usePreofferShoppingCart();
  }
  return {};
};

export default useShoppingCart;
