import { useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { shoppingCartOffersVar } from '../../apollo/reactive-variables/shopping-cart';
import { OFFER_SHOPPING_CART } from '../../graphql';

const useOfferShoppingCart = () => {
  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);

  const updatedShoppingCartOffers = shoppingCartOffers.map((offer) => {
    if (offer.dateToPay) {
      return {
        ...offer,
        dateToPay: offer.dateToPay
          ? offer.dateToPay.format('YYYY-MM-DD')
          : null,
      };
    }
    return offer;
  });

  const { data: shoppingCartData, loading } = useQuery(OFFER_SHOPPING_CART, {
    variables: { offerSelected: updatedShoppingCartOffers },
  });

  const noOffers = useMemo(
    () => shoppingCartOffers.length === 0,
    [shoppingCartOffers],
  );

  const someOfferWithoutDateToPay = useMemo(
    () => shoppingCartOffers.some((offer) => offer.dateToPay === null),
    [shoppingCartOffers],
  );

  const disabledShowCart = useMemo(
    () => noOffers || someOfferWithoutDateToPay,
    [noOffers, someOfferWithoutDateToPay],
  );

  return {
    shoppingCartData: shoppingCartData?.offerShoppingCart,
    disabledShowCart,
    loading,
  };
};

export default useOfferShoppingCart;
