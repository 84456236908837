import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { USER_INTERACTION_REGISTER } from '../graphql/user';

const useReactTrackingLayer = (frontendName) => {
  const utmCampaign = new URLSearchParams(window.location.search).get('utm_campaign') || '';
  const [registerAction] = useMutation(USER_INTERACTION_REGISTER, {
    onError: () => {},
  });
  const dispatcher = useCallback((trackingEvent) => {
    const {
      button,
      action,
      domain,
      route,
      searchParams,
      company,
      isMobile,
      session,
    } = trackingEvent;
    registerAction({
      variables: {
        action: {
          frontendName,
          route,
          domain,
          searchParams,
          company: company?.id,
          isMobile,
          action,
          button,
          session,
        },
      },
    });
  }, [registerAction, frontendName, utmCampaign]);
  return dispatcher;
};

export default useReactTrackingLayer;
