import { useMutation } from '@apollo/client';
import { UPLOAD_COMPANY_DOCUMENT } from '../graphql';
import { useSelectedCompany } from '.';

const useUploadCompanyDocument = () => {
  const selectedCompany = useSelectedCompany();

  const [uploadCompanyDocument, { loading }] = useMutation(
    UPLOAD_COMPANY_DOCUMENT,
  );

  const submit = ({ documentType, file, companyId = null, year = null }) => {
    uploadCompanyDocument({
      variables: {
        file,
        documentType,
        year,
        companyId: companyId !== null ? companyId : selectedCompany.id,
      },
    });
  };

  return { submit, loading };
};

export default useUploadCompanyDocument;
