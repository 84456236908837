import { useState } from 'react';

const useStepper = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const goNextStep = () => setCurrentStep((prevState) => prevState + 1);
  const goBackStep = () => setCurrentStep((prevState) => prevState - 1);
  return { currentStep, goBackStep, goNextStep, setCurrentStep };
};

export default useStepper;
