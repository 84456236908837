import { useReducer, useEffect } from 'react';

const ADD_ENDORSEMENT = 'ADD_ENDORSEMENT';
const REMOVE_ENDORSEMENT = 'REMOVE_ENDORSEMENT';
const UPDATE_ENDORSEMENT = 'UPDATE_ENDORSEMENT';

const endorsementsReducer = (state, action) => {
  switch (action.type) {
    case ADD_ENDORSEMENT:
      return [...state, action.payload];
    case REMOVE_ENDORSEMENT:
      return state.filter((endorsement) => endorsement.id !== action.payload);
    case UPDATE_ENDORSEMENT:
      return state.map((endorsement) => (endorsement.id
         === action.payload.id ? { ...endorsement, ...action.payload.data } : endorsement));
    default:
      return state;
  }
};

const useEndorsements = (availableEndorsements, mandatoryEndorsementRut) => {
  const [endorsements, dispatch] = useReducer(endorsementsReducer, []);

  const addEndorsement = (newEndorsement) => {
    dispatch({ type: ADD_ENDORSEMENT, payload: newEndorsement });
  };

  const removeEndorsement = (id) => {
    dispatch({ type: REMOVE_ENDORSEMENT, payload: id });
  };

  const updateEndorsement = (id, data) => {
    dispatch({ type: UPDATE_ENDORSEMENT, payload: { id, data } });
  };

  useEffect(() => {
    const mandatoryEndorsement = availableEndorsements.find((e) => e.rut
     === mandatoryEndorsementRut);
    if (mandatoryEndorsement && !endorsements.find((e) => e.rut === mandatoryEndorsementRut)) {
      addEndorsement(mandatoryEndorsement);
    }
  }, [availableEndorsements, mandatoryEndorsementRut]);

  return { endorsements, addEndorsement, removeEndorsement, updateEndorsement };
};

export default useEndorsements;
