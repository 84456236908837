import { useMutation } from '@apollo/client';
import { GENERATE_PORTFOLIO_URL } from '../graphql';
import { openUrlAndDownload } from '../helpers';
import useSnackBars from './useSnackBars';

const usePortfolioDownload = () => {
  const { addAlert } = useSnackBars();
  const [generatePortfolioUrl, { loading }] = useMutation(
    GENERATE_PORTFOLIO_URL,
    {
      onCompleted: (data) => openUrlAndDownload(data.generatePortfolioSummary.url, 'portfolio'),
      onError: (error) => {
        addAlert({
          id: 'recovery-password-error',
          message: error.message,
          color: 'error',
          severity: 'error',
        });
      } },
  );
  return {
    loading,
    generatePortfolioUrl,
  };
};

export default usePortfolioDownload;
