/* eslint-disable import/prefer-default-export */
import { useQuery } from '@apollo/client';
import { useCallback } from 'react';

const useFilteredQuery = (queryDocument, options) => {
  const {
    data, previousData, client, refetch, variables, ...result
  } = useQuery(queryDocument, options);
  const getQueryName = useCallback((document) => {
    const operationDefinitions = document.definitions.filter((def) => def.kind === 'OperationDefinition');
    return operationDefinitions[0].selectionSet.selections[0].name.value;
  }, []);
  const deleteQueryFromCache = useCallback(() => {
    const rootQuery = client.cache.data.data.ROOT_QUERY;
    const queryName = getQueryName(queryDocument);
    const matcher = new RegExp(`^(${queryName}):{.*}$`);
    Object.keys(rootQuery).forEach((key) => {
      if (key.match(matcher)) {
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: key });
      }
    });
  }, [client, queryDocument]);

  const deletePaginatedAndRefetch = useCallback(() => {
    deleteQueryFromCache();
    refetch(variables);
  }, [client, queryDocument, variables, deleteQueryFromCache, getQueryName, refetch]);
  return {
    data,
    previousData,
    usableData: data || previousData,
    refetch,
    client,
    variables,
    deletePaginatedAndRefetch,
    deleteQueryFromCache,
    ...result,
  };
};

export default useFilteredQuery;
