import { useQuery } from '@apollo/client';
import { GET_BANKS } from '../graphql';

const useAvailableBanks = (countryId) => {
  const { data: banks, loading } = useQuery(GET_BANKS, {
    variables: {
      countryId,
    } });
  return { banks, loading };
};

export default useAvailableBanks;
