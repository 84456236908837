import { useQuery } from '@apollo/client';
import { MAILS_WITH_PARAMETERS } from '../graphql/notification-center';

const useGetMails = (filters) => {
  const { data, loading, ...extraQuery } = useQuery(
    MAILS_WITH_PARAMETERS,
    { variables:
      {
        orderBy: 'emailName',
        ...filters,
      },
    },
  );
  return {
    loading,
    mails: data?.mails?.edges?.map((i) => i.node) || [],
    data,
    ...extraQuery,
  };
};

export default useGetMails;
