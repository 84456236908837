import { useMutation } from '@apollo/client';
import useSnackBars from './useSnackBars';
import { ADD_CONTROL_GROUP } from '../graphql';

const useAddControlGroup = ({ blackListTemplate }) => {
  const { addAlert } = useSnackBars();

  const [addControlGroup, { loadingControlGroup }] = useMutation(
    ADD_CONTROL_GROUP,
    {
      onCompleted: () => {
        if (!blackListTemplate) {
          addAlert({
            id: 'add-control-group',
            message: '¡Plantilla asignada a la empresa con éxito!',
          });
        }
      },
      onError: (e) => {
        addAlert({
          id: 'add-control-group-error',
          message: e.message,
          severity: 'error',
        });
      },
    },
  );
  return { addControlGroup, loadingControlGroup };
};
export default useAddControlGroup;
