import { useQuery } from '@apollo/client';

const useInvoices = (invoicesQuery, queryOptions) => {
  const realOptions = {
    ...queryOptions,
    variables: { ...queryOptions.variables, first: 100 },
  };
  const { data, ...extraQuery } = useQuery(
    invoicesQuery,
    realOptions,
  );

  return {
    invoices: data?.invoices?.edges?.map((edge) => edge.node) || [],
    ...extraQuery,
  };
};

export default useInvoices;
