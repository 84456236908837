import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { useSnackBars } from '.';
import { openUrlAndDownload } from '../helpers';

const useExportDocument = (query, variables, filename = 'documentos.xlsx', getUrl = (_data) => Object.values(_data)[0]) => {
  const { addAlert } = useSnackBars();
  const [_exportDocuments, { loading }] = useLazyQuery(
    query,
    {
      onCompleted: (_data) => {
        if (_data?.exportInvoices !== 'task') {
          openUrlAndDownload(getUrl(_data), filename);
        } else {
          addAlert({
            id: 'export-by-task',
            message: 'Te enviaremos un correo con el archivo descargable de tus documentos',
          });
        }
      },
      onError: () => {},
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );
  const exportDocuments = useCallback(() => {
    _exportDocuments({ variables });
  }, [variables]);
  return { exportDocuments, loading };
};

export default useExportDocument;
