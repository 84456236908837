import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { FETCH_PRESIGNED_URL } from '../graphql';

const useFetchPresignedUrl = (globalId, url) => {
  const openUrl = useCallback((fileUrl) => window.open(fileUrl), []);
  const [_fetchPresignedUrl, { loading }] = useMutation(FETCH_PRESIGNED_URL, {
    variables: {
      globalId,
    },
    onCompleted: (data) => openUrl(data.fetchPresignedUrl.url),
  });
  const fetchPresignedUrl = useCallback(() => {
    if (url) {
      openUrl(url);
      return;
    }
    if (!globalId) return;
    _fetchPresignedUrl();
  }, [globalId]);
  const fetchGivenGlobalId = useCallback((givenGlobalId, fileAttributeLabel = null) => {
    if (!givenGlobalId) {
      return;
    }
    _fetchPresignedUrl({ variables: {
      globalId: givenGlobalId,
      fileAttributeLabel,
    } });
  }, [_fetchPresignedUrl]);
  return [fetchPresignedUrl, loading, fetchGivenGlobalId];
};

export default useFetchPresignedUrl;
