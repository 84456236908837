import { useReducer } from 'react';

const ADD_LEGAL_REPRESENTATIVE = 'ADD_LEGAL_REPRESENTATIVE';
const REMOVE_LEGAL_REPRESENTATIVE = 'REMOVE_LEGAL_REPRESENTATIVE';
const UPDATE_LEGAL_REPRESENTATIVE = 'UPDATE_LEGAL_REPRESENTATIVE';

function legalRepresentativesReducer(state, action) {
  switch (action.type) {
    case ADD_LEGAL_REPRESENTATIVE:
      return [...state, action.payload];
    case REMOVE_LEGAL_REPRESENTATIVE:
      return state.filter((rep) => rep.id !== action.payload);
    case UPDATE_LEGAL_REPRESENTATIVE:
      return state.map((rep) => (rep.id
         === action.payload.id ? { ...rep, ...action.payload.data } : rep));
    default:
      return state;
  }
}

function useLegalRepresentatives() {
  const [legalRepresentatives, dispatch] = useReducer(legalRepresentativesReducer, []);

  const addLegalRepresentative = (newRepresentative) => {
    if (Array.isArray(newRepresentative)) {
      newRepresentative.forEach((rep) => {
        dispatch({ type: ADD_LEGAL_REPRESENTATIVE, payload: rep });
      });
    } else {
      dispatch({ type: ADD_LEGAL_REPRESENTATIVE, payload: newRepresentative });
    }
  };

  const removeLegalRepresentative = (id) => {
    dispatch({ type: REMOVE_LEGAL_REPRESENTATIVE, payload: id });
  };

  const updateLegalRepresentative = (id, data) => {
    dispatch({ type: UPDATE_LEGAL_REPRESENTATIVE, payload: { id, data } });
  };

  return {
    legalRepresentatives,
    addLegalRepresentative,
    removeLegalRepresentative,
    updateLegalRepresentative,
  };
}

export default useLegalRepresentatives;
