import { useEffect, useState } from 'react';
import useDebounce from './useDebounce';

const useAsyncHookSetter = (callback, delay = 500, initialState = '') => {
  const debounce = useDebounce(callback, delay);
  const [currentText, setAsyncHookValue] = useState(initialState);
  useEffect(() => {
    debounce(currentText);
  }, [currentText]);
  /* We return a list for further code extension */
  return [setAsyncHookValue];
};

export default useAsyncHookSetter;
